
// importing libraries
import React, { useEffect, useState, useRef } from "react";
import firebase from "../../Config/firebase";
// main functional component
export const Success = (props) => {
  // get seconds
  const [seconds, setSeconds] = useState(10);

  // get reference of seconds
  const foo = useRef();

  // this function will run first
  useEffect(() => {
    // getting order id from parameter
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const orderid = urlParams.get("order");

    // // get data of order by order id
    // firebase
    //   .database()
    //   .ref("orders")
    //   .child(orderid)
    //   .once("value", (snapshot) => {
    //     const dataVal = snapshot.val();

    //     // update order status to completed
    //     firebase.database().ref("orders").child(orderid).set({
    //       cid: dataVal.cid,
    //       tripid: dataVal.tripid,
    //       status: "Complete",
    //     });
    //   });

    // count to 10 seconds
    function tick() {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }
    foo.current = setInterval(() => tick(), 1000);
  }, []);

  useEffect(() => {
    // getting order id from parameter
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderid = urlParams.get("order");

    // after 10 seconds user will redirect to orderdetail page where he can add reviews
    if (seconds === 0) {
      clearInterval(foo.current);
      // window.location.href = "/customer/bookings";
            window.location.href = "/";

    }
  }, [seconds]);

  // rendering the UI
  return (
    <div
      style={{
        backgroundColor: "#1E2639",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 style={{ color: "white", fontSize: 50, fontWeight: "bolder" }}>
        Payment Success
      </h1>
      <br />
      <br />
      <p style={{ color: "white", fontSize: 25, fontWeight: "bolder" }}>
        Redirecting To Home Page In {seconds} Second
      </p>
    </div>
  );
};

export default Success;
