import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import firebase from "../../Config/firebase";
import { Sidebar } from "../../Components/sidebar.js";
import "../../Css/home.css";
import Modal from "react-bootstrap/Modal";

export const Trips = (props) => {
  const history = useNavigate();

  const [user, setUser] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [tripsdata, setTripdata] = useState([]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        history("/");
      } else {
        firebase
          .database()
          .ref("Admins")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();
            if (dataVal === null) {
              history("/");
            } else {
              setUser(dataVal);
              firebase
                .database()
                .ref("trips")
                .on("value", (snapshot) => {
                  let arr = [];
                  snapshot.forEach((data) => {
                    const dataVal = data.val();
                    dataVal.key = data.key;
                    arr.push(dataVal);
                  });
                  setTimeout(() => {
                    setTripdata(arr);
                    setIsloading(false);
                  }, 2000);
                });
            }
          });
      }
    });
  }, [history]);

  const deletetrip = (key) => {
    firebase.database().ref("trips").child(key).remove();
    alert("Trip Deleted");
  };

  // rendering the UI
  if (isLoading === true) {
    return (
      <>
        <div className="loadingscreen">
          <h1 style={{ fontSize: "45px", color: "white" }}>Please Wait</h1>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="page-top" style={{ width: "min-content", minWidth: "100vw" }}>
          <div id="wrapper" style={{ backgroundColor: "#131428" }}>
            {/* Importing Buyer Sidebar */}
            <Sidebar />

            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                {/* Importing Buyer Header */}
                <Header />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {/* The below code will display buyer name from user state */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <h1>Manage Trips</h1>
                    <Link to="/admin/addtrip" className="btn btn-success">
                      Add New Trip
                    </Link>
                  </div>
                  <br />
                  <div className="row">
                    {tripsdata.map((v, i) => {
                      return (
                        <div class="card col-md-3 m-5">
                          <br />
                          <img
                            class="card-img-top"
                            src={v.image}
                            alt="Card image cap"
                          />
                          <div class="card-body">
                            <h5 class="card-title">{v.tripname}</h5>
                            <p class="card-text">
                              <b>Beginning Location</b> {v.blocation} ,{" "}
                            </p>
                            <p>
                              {" "}
                              <b>Destination Location</b> {v.elocation}
                            </p>
                            <button
                              class="btn btn-danger"
                              onClick={() => deletetrip(v.key)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Trips;
