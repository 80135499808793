import React from "react";
import { useNavigate } from "react-router-dom";

export const Header = (props) => {
  const navigate = useNavigate();

  const gotohome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <div
      style={{
        backgroundColor: "#131428",
        height: "70px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <div>
        <h2
          style={{
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
            marginRight: "30px",
          }}
          className="htitle"
          onClick={gotohome}
        >
          Rabbi
        </h2>
      </div>
    </div>
  );
};

export default Header;
