import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import firebase from "../../Config/firebase";
import { Sidebar } from "../../Components/sidebar.js";
import "../../Css/home.css";

export const Counter = (props) => {
  const history = useNavigate();

  const [user, setUser] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const [homepage, setHomepage] = useState(0);
  const [about, setAbout] = useState(0);
  const [checkout, setCheckout] = useState(0);
  const [contact, setContact] = useState(0);
  const [search, setSearch] = useState(0);
  const [tripdetail, setTripdetail] = useState(0);
  const [login, setLogin] = useState(0);
  const [register, setRegister] = useState(0);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        history("/");
      } else {
        firebase
          .database()
          .ref("Admins")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();
            if (dataVal === null) {
              history("/");
            } else {
              setUser(dataVal);
              firebase
                .database()
                .ref("counter")
                .on("value", (snapshot) => {
                  if (snapshot.exists()) {
                    const dataVal = snapshot.val();
                    setHomepage(dataVal.homepage);
                    setAbout(dataVal.about);
                    setCheckout(dataVal.checkout);
                    setContact(dataVal.contact);
                    setSearch(dataVal.search);
                    setTripdetail(dataVal.tripdetail);
                    setLogin(dataVal.login);
                    setRegister(dataVal.register);
                  }
                });
              setTimeout(() => {
                setIsloading(false);
              }, 2000);
            }
          });
      }
    });
  }, [history]);

  // rendering the UI
  if (isLoading === true) {
    return (
      <>
        <div className="loadingscreen">
          <h1 style={{ fontSize: "45px", color: "white" }}>Please Wait</h1>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="page-top" style={{ width: "min-content", minWidth: "100vw" }}>
          <div id="wrapper" style={{ backgroundColor: "#131428" }}>
            {/* Importing Buyer Sidebar */}
            <Sidebar />

            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                {/* Importing Buyer Header */}
                <Header />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {/* The below code will display buyer name from user state */}
                  <h1>Welcome {user.name}</h1>
                  <br />
                  <div className="row">
                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Login Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{login}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Register Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{register}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Home Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{homepage}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">About Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{about}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Contact Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{contact}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Search Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{search}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Trip Details Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{tripdetail}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div class="card">
                        <div class="card-header">Checkout Page</div>
                        <div class="card-body">
                          <h5 class="card-title">{checkout}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Counter;
