import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainHeader from "../Components/mainheader";
import MobileHeader from "../Components/mobileheader";
import firebase from "../Config/firebase";
const Contact = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    firebase
      .database()
      .ref("counter")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const dataVal = snapshot.val();
          dataVal.contact += 1;

          console.log(dataVal);
          firebase.database().ref("counter").set(dataVal);
        } else {
          firebase.database().ref("counter").set({
            homepage: 0,
            about: 0,
            checkout: 0,
            login: 0,
            register: 0,
            tripdetail: 0,
            search: 0,
            contact: 1,
          });
        }
      });
    setTimeout(() => {
      document.getElementById("myloader").style.display = "none";
    }, 2000);
  }, []);

  const submit = () => {
    if (fullname === "" || email === "" || message === "") {
      alert("Please Provide All Required Information");
    } else {
      firebase.database().ref("messages").push({
        customername: fullname,
        customeremail: email,
        message: message,
      });

      alert("Message Has Been Sent");

      setFullname("");
      setEmail("");
      setMessage("");
    }
  };
  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
            Loader End
**************************************/}
      {/*************************************
        Mobile Menu Start
**************************************/}
      <MobileHeader />{" "}
      {/*************************************
        Mobile Menu End
**************************************/}
      {/*************************************
        Wrapper Start
**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
            Header Start
    **************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
            Header End
    **************************************/}
        {/*************************************
            Inner Banner Start
    **************************************/}
        <section
          className="tg-parallax tg-innerbanner"
          data-appear-top-offset={600}
          data-parallax="scroll"
          style={{
            backgroundImage: 'url("images/lake1.jpeg")',
            backgroundSize: "cover",
          }}
        >
          <div className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h1>Contact Us</h1>
                  <h2>The Boat You Like</h2>
                  <ol className="tg-breadcrumb">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="tg-active">Contact Us</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*************************************
            Inner Banner End
    **************************************/}
        {/*************************************
            Main Start
    **************************************/}
        <main id="tg-main" className="tg-main tg-sectionspace tg-haslayout">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div id="tg-content" className="tg-content">
                  <ul className="tg-contactinfo">
                    <li>
                      <span className="tg-contactinfoicon">
                        <i className="r" />
                      </span>
                      <h2></h2>
                      <address></address>
                      <strong>
                        <a href="#"></a>
                      </strong>
                    </li>
                    <li>
                      <span className="tg-contactinfoicon">
                        <i className="fa fa-commenting-o" />
                      </span>
                      <h2>Get in Touch</h2>
                      <span>Telephone: +447440171093</span>
                      {/* <span>Mobile: +501 860 3210</span> */}
                      <strong>
                        <a href="mailto:raygombo@gmail.com">
                          raygombo@gmail.com
                        </a>
                      </strong>
                    </li>

                    <li>
                      <span className="tg-contactinfoicon">
                        <i className="" />
                      </span>
                      <h2></h2>
                      <div className="tg-description">
                        <p></p>
                      </div>
                      <strong>
                        <a href="mailto:careers@RABBI.com"></a>
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xs-offset-2 col-sm-offset-2 col-md-offset-2 col-lg-offset-2">
                <div id="tg-content" className="tg-content">
                  <h2>
                    <center>Contact Us</center>
                  </h2>
                  <hr />
                  <p />
                  <center>
                    For all enquiries, please email us using the form below.
                  </center>
                  <p />
                  <div className="tg-formtheme tg-formlogin">
                    <fieldset>
                      <div className="form-group">
                        <label>
                          Full Name <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          name="fullname"
                          className="form-control"
                          placeholder
                          required
                          value={fullname}
                          onChange={(e) => setFullname(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Email <sup>*</sup>
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Message <sup>*</sup>
                        </label>
                        <textarea
                          className="form-control"
                          name="message"
                          required
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />{" "}
                      </div>
                      <button className="tg-btn tg-btn-lg" onClick={submit}>
                        <span>Send</span>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*************************************
            Main End
    **************************************/}
        {/*************************************
            Footer Start
    **************************************/}
        <footer id="tg-footer" className="tg-footer tg-haslayout">
          <div className="tg-fourcolumns">
            <div className="container">
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <div className="tg-footercolumn tg-widget tg-widgettext">
                    <div className="tg-widgettitle">
                      <h3>About Comapny</h3>
                    </div>
                    <div className="tg-widgetcontent">
                      <div className="tg-description">
                        <p>
                          Nunc cursus liero purs ac cogue arcu cursus ut sed
                          vitae pulvinar massaidp nequetiam lore elerisque
                        </p>
                      </div>
                      <span>1-800-321-6543</span>
                      <a href="mailto:info@RABBI.com">info@RABBI.com</a>
                      <ul className="tg-socialicons tg-socialiconsvtwo">
                        <li>
                          <a href="javascript:void(0);">
                            <i className="icon-facebook-logo-outline" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tg-footerbar">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <p>Copyright © 2021 Comapny. All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*************************************
            Footer End
    **************************************/}
      </div>
    </div>
  );
};

export default Contact;
