import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import MainHeader from "../Components/mainheader";
import MobileHeader from "../Components/mobileheader";
import firebase from "../Config/firebase";

const About = () => {
  useEffect(() => {
    firebase
      .database()
      .ref("counter")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const dataVal = snapshot.val();
          dataVal.about += 1;

          console.log(dataVal);
          firebase.database().ref("counter").set(dataVal);
        } else {
          firebase.database().ref("counter").set({
            homepage: 0,
            about: 1,
            checkout: 0,
            login: 0,
            register: 0,
            tripdetail: 0,
            search: 0,
            contact: 0,
          });
        }
      });

    setTimeout(() => {
      document.getElementById("myloader").style.display = "none";
    }, 2000);
  }, []);
  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      <MobileHeader />
      {/*************************************
			Mobile Menu End
	**************************************/}
      {/*************************************
			Wrapper Start
	**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
				Header Start
		**************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
				Header End
		**************************************/}
        {/*************************************
				Inner Banner Start
		**************************************/}
        <section
          className="tg-parallax tg-innerbanner"
          data-appear-top-offset={600}
          data-parallax="scroll"
          style={{
            backgroundImage: 'url("images/lake1.jpeg")',
            backgroundSize: "cover",
          }}
        >
          <div className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h1>About Us</h1>
                  <h2>The Boat You Like</h2>
                  <ol className="tg-breadcrumb">
                    <li>
                      <a href="javascript:void(0);">Home</a>
                    </li>
                    <li className="tg-active">about us</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*************************************
				Inner Banner End
		**************************************/}
        {/*************************************
				Main Start
		**************************************/}
        <main id="tg-main" className="tg-main tg-haslayout">
          {/*************************************
					Features Start
			**************************************/}
          {/* <section className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="tg-features">
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <div className="tg-feature">
                      <div className="tg-featuretitle">
                        <h2>
                          <span>01</span>
                          <a href>About RABBI</a>
                        </h2>
                      </div>
                      <div className="tg-description">
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh tempor cum soluta nobis
                          consectetuer nihil imperdiet doming...
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <div className="tg-feature">
                      <div className="tg-featuretitle">
                        <h2>
                          <span>02</span>
                          <a href="javascript:void(0);">Tourist Guide</a>
                        </h2>
                      </div>
                      <div className="tg-description">
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh tempor cum soluta nobis
                          consectetuer nihil imperdiet doming...
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <div className="tg-feature">
                      <div className="tg-featuretitle">
                        <h2>
                          <span>03</span>
                          <a href="javascript:void(0);">Ship Tickets</a>
                        </h2>
                      </div>
                      <div className="tg-description">
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh tempor cum soluta nobis
                          consectetuer nihil imperdiet doming...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <br />
          <br />
          {/*************************************
					Features End
			**************************************/}
          <section className="tg-aboutus">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="row">
                <br />
                <br />
                <br />
                <img
                  src="images/lake1.jpeg"
                  style={{ width: "100%", marginTop: "20%" }}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="row">
                <div className="tg-textbox">
                  <div className="tg-sectiontitle">
                    <h2>A Little About Us</h2>
                  </div>
                  <div className="tg-description">
                    <p>
                      In the beginning was the Word, and the Word was with God,
                      and the Word was God.
                    </p>
                    <p>
                      He was in the beginning with God. All things were made
                      through Him, and without Him
                    </p>
                    <p>
                      <p>
                        Nothing was made that was made. In Him was life, and the
                        life was the light of men
                      </p>
                      <p>
                        He had come to earth to bring us the works of the
                        Kingdom of God and the disciples called him Master
                        (Rabbi) because they expected from him the teachings,
                        including the building of this Kingdom.
                      </p>
                      <p>
                        This truth remains relevant among us, and it is the role
                        of the present enterprise: To serve in the love of God
                        whose dividends are intended for the establishment of
                        his Kingdom.
                      </p>
                      <p>
                        This is the task that has fallen to us; Guardian of his
                        heritage
                      </p>
                      <p>
                        May the staff, collaborator, customer of our Company be
                        covered by the glorious Blood of Jesus.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*************************************
					About Us End
			**************************************/}
          {/*************************************
					FAQs Start
			**************************************/}
          {/* <section className="tg-sectionspace tg-haslayout tg-bglight">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="tg-sectionhead">
                    <div className="tg-sectiontitle">
                      <h2>“Frequently Asked Questions.</h2>
                    </div>
                    <div className="tg-description">
                      <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam consectetuer.
                      </p>
                    </div>
                  </div>
                  <div
                    id="tg-accordion"
                    className="tg-accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="tg-panel">
                      <h4>Ornare Quam Justo Tellusv</h4>
                      <div className="tg-panelcontent">
                        <div className="tg-description">
                          <p>
                            Maecenas sed diam eget risus varius blandit sit amet
                            non magna. Vivamus sagittis lacus vel augue Sed non
                            mauris vitae;erat consequat auctor eu in elit. Class
                            aptent taciti sociosqu ad litora torquent per
                            conubia nostra, per inceptos himenaeos. Mauris in
                            erat justo.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tg-panel">
                      <h4>A work of art is above all an adventure of mind!</h4>
                      <div className="tg-panelcontent">
                        <div className="tg-description">
                          <p>
                            Maecenas sed diam eget risus varius blandit sit amet
                            non magna. Vivamus sagittis lacus vel augue Sed non
                            mauris vitae;erat consequat auctor eu in elit. Class
                            aptent taciti sociosqu ad litora torquent per
                            conubia nostra, per inceptos himenaeos. Mauris in
                            erat justo.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tg-panel">
                      <h4>Pharetra Etiam Inceptos</h4>
                      <div className="tg-panelcontent">
                        <div className="tg-description">
                          <p>
                            Maecenas sed diam eget risus varius blandit sit amet
                            non magna. Vivamus sagittis lacus vel augue Sed non
                            mauris vitae;erat consequat auctor eu in elit. Class
                            aptent taciti sociosqu ad litora torquent per
                            conubia nostra, per inceptos himenaeos. Mauris in
                            erat justo.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tg-panel">
                      <h4>We pride ourselves on innovative.</h4>
                      <div className="tg-panelcontent">
                        <div className="tg-description">
                          <p>
                            Maecenas sed diam eget risus varius blandit sit amet
                            non magna. Vivamus sagittis lacus vel augue Sed non
                            mauris vitae;erat consequat auctor eu in elit. Class
                            aptent taciti sociosqu ad litora torquent per
                            conubia nostra, per inceptos himenaeos. Mauris in
                            erat justo.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*************************************
					FAQs End
			**************************************/}
        </main>
        {/*************************************
				Main End
		**************************************/}
        {/*************************************
				Footer Start
		**************************************/}
        <Footer />
        {/*************************************
				Footer End
		**************************************/}
      </div>
    </div>
  );
};

export default About;
