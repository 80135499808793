import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/footer";
import MainHeader from "../Components/mainheader";
import MobileHeader from "../Components/mobileheader";
import Slider from "../Components/slider";
import firebase from "../Config/firebase";
const Home = () => {
  const [tripsdata, setTripdata] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    firebase
      .database()
      .ref("trips")
      .on("value", (snapshot) => {
        let arr = [];
        snapshot.forEach((data) => {
          const dataVal = data.val();
          dataVal.key = data.key;
          arr.push(dataVal);
        });
        firebase
          .database()
          .ref("counter")
          .once("value", (snapshot) => {
            if (snapshot.exists()) {
              const dataVal = snapshot.val();
              dataVal.homepage += 1;

              console.log(dataVal);
              firebase.database().ref("counter").set(dataVal);
            } else {
              firebase.database().ref("counter").set({
                homepage: 1,
                about: 0,
                checkout: 0,
                login: 0,
                register: 0,
                tripdetail: 0,
                search: 0,
                contact: 0,
              });
            }
          });
        setTimeout(() => {
          setTripdata(arr);
          document.getElementById("myloader").style.display = "none";
        }, 2000);
      });
  }, []);

  const gotodetail = (key) => {
    history("/tourbookingdetail", {
      state: {
        key: key,
      },
    });
  };
  return (
    <div>
      {/*************************************
				Loader Start
	**************************************/}
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
				Loader End
	**************************************/}
      {/*************************************
			Mobile Menu Start
	**************************************/}
      <MobileHeader />
      {/*************************************
			Mobile Menu End
	**************************************/}
      {/*************************************
			Wrapper Start
	**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
				Header Start
		**************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>

                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
				Header End
		**************************************/}
        {/*************************************
				Home Slider Start
		**************************************/}
        <Slider />
        {/*************************************
				Home Slider End
		**************************************/}
        {/*************************************
				Main Start
		**************************************/}
        <main id="tg-main" className="tg-main tg-haslayout">
          {/*************************************
					Popular Tour Start
			**************************************/}
          <div
            className="container-fluid"
            style={{ backgroundColor: "#FF9A8C" }}
          >
            <br />
            <h2>Popular Trips</h2>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              {tripsdata.map((v, i) => {
                return (
                  <div key={i} class="card col-md-3 m-3">
                    <br />
                    <img
                      class="card-img-top"
                      src={v.image}
                      alt="Card image cap"
                      style={{ height: "300px" }}
                    />
                    <div class="card-body">
                      <h2 class="card-title">{v.tripname}</h2>
                      <p class="card-text">
                        <b>Beginning Location</b> {v.blocation} ,{" "}
                      </p>
                      <p>
                        {" "}
                        <b>Destination Location</b> {v.elocation}
                      </p>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>
                          <b>Price : </b>$ {v.price}
                        </h3>
                        <button
                          onClick={() => gotodetail(v.key)}
                          style={{
                            backgroundColor: "#FF9A8C",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/*************************************
					Popular Tour End
			**************************************/}
          {/*************************************
					Our Destination Start
			**************************************/}
          {/* <section className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="tg-ourdestination">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 tg-verticalmiddle">
                    <figure>
                      <img src="images/lake2.jpg" alt="image destinations" />
                    </figure>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 tg-verticalmiddle">
                    <div className="tg-ourdestinationcontent">
                      <div className="tg-sectiontitle tg-sectiontitleleft">
                        <h2>Popular Trip Destinations</h2>
                      </div>
                      <div className="tg-description">
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam consectetuer adipiscing elit, sed diam
                          nonummy nibh...
                        </p>
                      </div>
                      <ul className="tg-destinations">
                        <li>
                          <a href="#">
                            <h3>Baraka</h3>
                            <em>(01)</em>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <h3>Dine</h3>
                            <em>(01)</em>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <h3>Kazimia</h3>
                            <em>(01)</em>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <h3>Kihimino</h3>
                            <em>(01)</em>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <h3>Mwayenga</h3>
                            <em>(01)</em>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <h3>Uvira</h3>
                            <em>(01)</em>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*************************************
					Our Destination End
			**************************************/}
          {/*************************************
					Call To Action Start
			**************************************/}
          {/* <section
            className="tg-parallax"
            data-appear-top-offset={600}
            data-parallax="scroll"
            data-image-src="images/lake2.jpg"
          >
            <div className="tg-sectionspace tg-haslayout">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tg-calltoaction">
                      <div className="tg-pattern">
                        <img
                          src="images/patternw.png"
                          alt="image destination"
                        />
                      </div>
                      <h2>Get 10% Off on your Next Travel</h2>
                      <div className="tg-description">
                        <p>Discount description here</p>
                      </div>
                      <a className="tg-btn" href="#">
                        <span>Explore Tour</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*************************************
					Call To Action End
			**************************************/}
        </main>
        {/*************************************
				Main End
		**************************************/}
        {/*************************************
				Footer Start
		**************************************/}
        <Footer />
        {/*************************************
				Footer End
		**************************************/}
      </div>
    </div>
  );
};

export default Home;
