import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/footer";
import MainHeader from "../Components/mainheader";
import MobileHeader from "../Components/mobileheader";
import firebase from "../Config/firebase";
const SearchResult = () => {
  const history = useNavigate();

  const [tripdata, setTripdata] = useState([]);
  useEffect(() => {
    var url = new URL(window.location.href);
    var beginning = url.searchParams.get("beginning");
    var destination = url.searchParams.get("destination");
    firebase
      .database()
      .ref("trips")
      .on("value", (snapshot) => {
        let arr = [];
        snapshot.forEach((data) => {
          const dataVal = data.val();
          if (
            dataVal.blocation === beginning &&
            dataVal.elocation === destination
          ) {
            dataVal.key = data.key;

            arr.push(dataVal);
          }
        });
        setTripdata(arr);
      });
    firebase
      .database()
      .ref("counter")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const dataVal = snapshot.val();
          dataVal.search += 1;

          console.log(dataVal);
          firebase.database().ref("counter").set(dataVal);
        } else {
          firebase.database().ref("counter").set({
            homepage: 0,
            about: 0,
            checkout: 0,
            login: 0,
            register: 0,
            tripdetail: 0,
            search: 1,
            contact: 0,
          });
        }
      });
    setTimeout(() => {
      document.getElementById("myloader").style.display = "none";
    }, 2000);
  }, []);

  const gotodetail = (key) => {
    history("/tourbookingdetail", {
      state: {
        key: key,
      },
    });
  };
  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
            Loader End
**************************************/}
      {/*************************************
        Mobile Menu Start
**************************************/}
      <MobileHeader />
      {/*************************************
        Mobile Menu End
**************************************/}
      {/*************************************
        Wrapper Start
**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
            Header Start
    **************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
            Header End
    **************************************/}
        {/*************************************
            Inner Banner Start
    **************************************/}
        <section
          className="tg-parallax tg-innerbanner"
          data-appear-top-offset={600}
          data-parallax="scroll"
          data-image-src="images/lake2.jpg"
        >
          <div className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h1>RABBI</h1>
                  <h2>The cruise you love</h2>
                  <ol className="tg-breadcrumb">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="tg-active">Results</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*************************************
            Inner Banner End
    **************************************/}
        {/*************************************
            Main Start
    **************************************/}
        <main
          id="tg-main"
          className="tg-main tg-sectionspace tg-haslayout tg-bglight"
        >
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div id="tg-content" className="tg-content">
                  <div className="tg-listing tg-listingvthree">
                    <div className="tg-sectiontitle">
                      <h2>Search Results</h2>
                    </div>
                    {tripdata.map((v, i) => {
                      return (
                        <div className="tg-populartour tg-populartourss tg-populartourvtwo">
                          <figure>
                            <a href="/tourbookingdetail">
                              <img
                                src="images/lake2.jpg"
                                alt="image destinations"
                              />
                            </a>
                          </figure>
                          <div className="tg-populartourcontent">
                            <div className="tg-populartourtitle">
                              <h3>{v.tripname} </h3>
                            </div>
                            <div className="tg-description">
                              <p>{v.description}</p>
                            </div>
                            <div className="tg-populartourfoot">
                              <div className="tg-durationrating">
                                <span className="tg-tourduration">7 Days</span>
                              </div>
                              <div
                                className="tg-btn"
                                style={{ cursor: "pointer" }}
                                onClick={() => gotodetail(v.key)}
                              >
                                <span>Book Now</span>
                              </div>
                            </div>
                            <div className="tg-priceavailability">
                              <div className="tg-availhead">
                                <time dateTime="2017-12-12">
                                  Availability : {v.startdate} - {v.endddate}
                                </time>
                              </div>
                              <div className="tg-pricearea">
                                <h4>${v.price}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*************************************
            Main End
    **************************************/}
        {/*************************************
            Footer Start
    **************************************/}
        <Footer />
        {/*************************************
            Footer End
    **************************************/}
      </div>
    </div>
  );
};

export default SearchResult;
