import React from "react";
import Home from "../Pages/home.js";
import About from "../Pages/about.js";
import Contact from "../Pages/contact.js";
import Login from "../Pages/login.js";
import Register from "../Pages/register.js";
import SearchResult from "../Pages/searchresult.js";
import TripDetail from "../Pages/tripdetail.js";
import Checkout from "../Pages/checkout.js";

import { Route, Routes } from "react-router-dom";
import { Dashboard } from "../Pages/Admin/dashboard.js";
import Trips from "../Pages/Admin/trips.js";
import AddTrip from "../Pages/Admin/addtrip.js";
import Bookings from "../Pages/Admin/bookings.js";
import CustomerBookings from "../Pages/Customers/customerbookings.js";
import CustomerDashboard from "../Pages/Customers/customerdashboard.js";
import Messages from "../Pages/Admin/messages.js";
import { Failed } from "../Pages/Customers/Failed.js";
import { Success } from "../Pages/Customers/Success.js";
import Counter from "../Pages/Admin/counter.js";

function AppRouter() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/searchresults" element={<SearchResult />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/tourbookingdetail" element={<TripDetail />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/counter" element={<Counter />} />

        <Route path="/admin/trips" element={<Trips />} />
        <Route path="/admin/addtrip" element={<AddTrip />} />
        <Route path="/admin/bookings" element={<Bookings />} />
        <Route path="/admin/messages" element={<Messages />} />
        <Route path="/customer/dashboard" element={<CustomerDashboard />} />
        <Route path="/customer/bookings" element={<CustomerBookings />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failed" element={<Failed />} />
      </Routes>
    </>
  );
}

export default AppRouter;
