import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

// copy var firebaseConfig object AND firebase.initializeApp(firebaseConfig) line from firebase project console to he

const firebaseConfig = {
  apiKey: "AIzaSyC7JZ7s3t5r1xJv0c-YEfJuidgReI4wDB4",
  authDomain: "rabbi-live.firebaseapp.com",
  projectId: "rabbi-live",
  storageBucket: "rabbi-live.appspot.com",
  messagingSenderId: "332973793058",
  appId: "1:332973793058:web:6e20b3d06c5f6ae91a0f7b",
  measurementId: "G-VPR6PF5HKE",
};

export default firebase.initializeApp(firebaseConfig);
