import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomerSidebar from "../../Components/customersiebar";
import Header from "../../Components/header";
import firebase from "../../Config/firebase";
import "../../Css/home.css";

export const CustomerBookings = (props) => {
  const history = useNavigate();

  const [user, setUser] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        history("/");
      } else {
        firebase
          .database()
          .ref("Customers")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();
            if (dataVal === null) {
              history("/");
            } else {
              setUser(dataVal);
              firebase
                .database()
                .ref("orders")
                .on("value", (snapshot) => {
                  let arr = [];
                  if (snapshot.exists()) {
                    snapshot.forEach((data) => {
                      const dataV = data.val();
                      if (dataV.cid === firebaseUser.email) {
                        firebase
                          .database()
                          .ref("trips")
                          .child(dataV.tripid)
                          .on("value", (snapshot) => {
                            const da = snapshot.val();
                            console.log(da);
                            arr.push(da);
                          });
                      }
                    });
                    setTimeout(() => {
                      setBookings(arr);
                      setIsloading(false);
                    }, 2000);
                  } else {
                    setIsloading(false);
                  }
                });
            }
          });
      }
    });
  }, [history]);

  // rendering the UI
  if (isLoading === true) {
    return (
      <>
        <div className="loadingscreen">
          <h1 style={{ fontSize: "45px", color: "white" }}>Please Wait</h1>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="page-top" style={{ width: "min-content", minWidth: "100vw" }}>
          <div id="wrapper" style={{ backgroundColor: "#131428" }}>
            {/* Importing Buyer Sidebar */}
            <CustomerSidebar />

            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                {/* Importing Buyer Header */}
                <Header />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {/* The below code will display buyer name from user state */}
                  <h1>All Bookings</h1>
                  <br />
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        <th>Trip Name</th>
                        <th>Starting Location</th>
                        <th>Destination Location</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Price</th>
                        <th>No Of Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>{v.tripname}</td>
                            <td>{v.blocation}</td>
                            <td>{v.elocation}</td>
                            <td>{v.startdate}</td>
                            <td>{v.endddate}</td>
                            <td>{v.price}</td>
                            <td>{v.days}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default CustomerBookings;
