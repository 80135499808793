import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import firebase from "../../Config/firebase";
import { Sidebar } from "../../Components/sidebar.js";
import "../../Css/home.css";

export const Dashboard = (props) => {
  const history = useNavigate();

  const [user, setUser] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        history("/");
      } else {
        firebase
          .database()
          .ref("Admins")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();
            if (dataVal === null) {
              history("/");
            } else {
              setUser(dataVal);
              setTimeout(() => {
                setIsloading(false);
              }, 2000);
            }
          });
      }
    });
  }, [history]);

  // rendering the UI
  if (isLoading === true) {
    return (
      <>
        <div className="loadingscreen">
          <h1 style={{ fontSize: "45px", color: "white" }}>Please Wait</h1>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="page-top" style={{ width: "min-content", minWidth: "100vw" }}>
          <div id="wrapper" style={{ backgroundColor: "#131428" }}>
            {/* Importing Buyer Sidebar */}
            <Sidebar />

            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                {/* Importing Buyer Header */}
                <Header />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {/* The below code will display buyer name from user state */}
                  <h1>Welcome {user.name}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Dashboard;
