import React from "react";

function Slider() {
  return (
    <>
      <div className="tg-bannerholder">
        <div className="tg-slidercontent">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h2>Le meilleur moyen de voyager sur le kac Tanganyika</h2>
                {/* <h1>Experience the Wonder</h1>
                <h2>People don’t take trips, trips take People</h2> */}
                <form
                  className="tg-formtheme tg-formtrip"
                  method="GET"
                  action="/searchresults"
                >
                  <fieldset>
                    <div className="form-group">
                      <div className="tg-select">
                        <select
                          className="selectpicker"
                          name="beginning"
                          data-live-search="true"
                          data-width="100%"
                        >
                          <option data-tokens="Beginning">Beginning</option>
                          <option data-tokens="Baraka">Baraka</option>
                          <option data-tokens="Dine">Dine</option>
                          <option data-tokens="Kazimia">Kazimia</option>
                          <option data-tokens="Kihimino">Kihimino</option>
                          <option data-tokens="Mwayenga">Mwayenga</option>
                          <option data-tokens="Uvira">Uvira</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="tg-select">
                        <select
                          className="selectpicker"
                          name="destination"
                          data-live-search="true"
                          data-width="100%"
                        >
                          <option data-tokens="Destination">Destination</option>
                          <option data-tokens="Baraka">Baraka</option>
                          <option data-tokens="Dine">Dine</option>
                          <option data-tokens="Kazimia">Kazimia</option>
                          <option data-tokens="Kihimino">Kihimino</option>
                          <option data-tokens="Mwayenga">Mwayenga</option>
                          <option data-tokens="Uvira">Uvira</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <button className="tg-btn" type="submit">
                        <span>Find Trips</span>
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tg-homeslider"
          className="tg-homeslider owl-carousel tg-haslayout"
        >
          <figure
            className="item"
            data-vide-bg="poster: images/lake2.jpg"
            data-vide-options="position: 0% 50%"
          />
          <figure
            className="item"
            data-vide-bg="poster: images/lake4.jpg"
            data-vide-options="position: 0% 50%"
          />
          <figure
            className="item"
            data-vide-bg="poster: images/lake3.jpg"
            data-vide-options="position: 0% 50%"
          />
        </div>
      </div>
    </>
  );
}

export default Slider;
