import React, { useState, useEffect } from "react";
// import "./App.css";
import firebase from "../Config/firebase";
import Footer from "../Components/footer";
import MobileHeader from "../Components/mobileheader";
import MainHeader from "../Components/mainheader";
import { useLocation, useNavigate } from "react-router-dom";

const Checkout = (props) => {
  const location = useLocation();
  const history = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");

  const [cardname, setCardName] = useState("");
  const [cardno, setCardno] = useState("");
  const [expmonth, setExpmonth] = useState("");
  const [expyear, setExpyear] = useState("");
  const [cvv, setCvv] = useState("");
  const [amount, setAmount] = useState(50);
  const [tripname, setTripname] = useState("");
  const [tripid, setTripid] = useState("");

  const [disabled, setDisabled] = useState(false);

  const makesession = async (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const mypay = async () => {
    setDisabled(true);

    if (
      name === "" ||
      email === "" ||
      address === "" ||
      city === "" ||
      postcode === "" ||
      country === "" ||
      cardname === "" ||
      cardno === "" ||
      expmonth === "" ||
      expyear === "" ||
      cvv === ""
    ) {
      alert("Please Provide All Details");
      setDisabled(false);
    } else {
      let sessionid = await makesession(25);
      console.log(sessionid);
      await firebase.database().ref("sessions").child(sessionid).set({
        session: sessionid,
      });

      setTimeout(async () => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: name,
            cardno: cardno,
            expmonth: expmonth,
            expyear: expyear,
            cvv: cvv,
            amount: amount,
            cardname: cardname,
            address: address,
            postcode: postcode,
            country: country,
            city: city,
            email: email,
            session: sessionid,
            tripname: tripname,
            tripid: tripid,
          }),
        };
        const response = await fetch(
          "https://rabbicheckout.herokuapp.com/newcheckout",
          requestOptions
        );
        const data = await response.json();
        console.log(data);
        if (data.status === "succeeded") {
          alert("Payment Completed");
          history("/success");

          //save data to firebase here or we can also save data in firebase from server

          setDisabled(false);
        } else {
          alert(data.error);
          setDisabled(false);
        }
      }, 500);
    }
  };

  useEffect(() => {
    console.log(location);
    if (location.state !== null && location.state !== undefined) {
      console.log(location.state.tripid);
      let pr = parseInt(location.state.tripprice);
      setAmount(pr);
      setTripid(location.state.tripid);
      setTripname(location.state.triptitle);
      firebase
        .database()
        .ref("counter")
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const dataVal = snapshot.val();
            dataVal.checkout += 1;

            console.log(dataVal);
            firebase.database().ref("counter").set(dataVal);
          } else {
            firebase.database().ref("counter").set({
              homepage: 0,
              about: 0,
              checkout: 1,
              login: 0,
              register: 0,
              tripdetail: 0,
              search: 0,
              contact: 0,
            });
          }
        });
      setTimeout(() => {
        document.getElementById("myloader").style.display = "none";
      }, 2000);
    } else {
      history("/");
    }
  }, []);

  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
				Loader End
	**************************************/}
      {/*************************************
			Mobile Menu Start
	**************************************/}
      <MobileHeader />
      {/*************************************
			Mobile Menu End
	**************************************/}
      {/*************************************
			Wrapper Start
	**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
				Header Start
		**************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="../images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
				Header End
		**************************************/}

        {/*************************************
				Inner Banner Start
		**************************************/}
        <section
          className="tg-parallax tg-innerbanner"
          data-appear-top-offset={600}
          data-parallax="scroll"
          style={{
            backgroundImage: 'url("images/lake1.jpeg")',
            backgroundSize: "cover",
          }}
        >
          <div className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h1>Checkout</h1>
                  <br />
                  <br />

                  <ol className="tg-breadcrumb">
                    <li className="tg-active">Checkout</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*************************************
				Inner Banner End
		**************************************/}

        {/*************************************
				Main Start
		**************************************/}
        <main id="tg-main" className="tg-main tg-haslayout">
          <div className="appsss">
            <div className="row">
              <div className="col-75">
                <div className="containersss">
                  <div className="form">
                    <div className="row">
                      <div className="col-50">
                        <h3>Billing Address</h3>
                        <label htmlFor="fname">
                          <i className="fa fa-user" /> Full Name
                        </label>
                        <input
                          type="text"
                          id="fname"
                          name="firstname"
                          placeholder="John M. Doe"
                          value={name}
                          className="checkouttext"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label htmlFor="email">
                          <i className="fa fa-envelope" /> Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="checkouttext"
                          placeholder="john@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor="adr">
                          <i className="fa fa-address-card-o" /> Address
                        </label>
                        <input
                          type="text"
                          id="adr"
                          name="address"
                          className="checkouttext"
                          placeholder="542 W. 15th Street"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <label htmlFor="city">
                          <i className="fa fa-institution" /> City
                        </label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="checkouttext"
                          placeholder="New York"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <div className="row">
                          <div className="col-50">
                            <label htmlFor="zip">Zip</label>
                            <input
                              type="text"
                              id="zip"
                              name="zip"
                              className="checkouttext"
                              placeholder={10001}
                              value={postcode}
                              onChange={(e) => setPostcode(e.target.value)}
                            />
                          </div>
                          <div className="col-50">
                            <label htmlFor="country">Country</label>
                            <select
                              id="country"
                              name="country"
                              value={country}
                              className="checkoutselect"
                              onChange={(e) => setCountry(e.target.value)}
                            >
                              <option value="">Select Country</option>
                              <option value="AF">Afghanistan</option>
                              <option value="AX">Aland Islands</option>
                              <option value="AL">Albania</option>
                              <option value="DZ">Algeria</option>
                              <option value="AS">American Samoa</option>
                              <option value="AD">Andorra</option>
                              <option value="AO">Angola</option>
                              <option value="AI">Anguilla</option>
                              <option value="AQ">Antarctica</option>
                              <option value="AG">Antigua and Barbuda</option>
                              <option value="AR">Argentina</option>
                              <option value="AM">Armenia</option>
                              <option value="AW">Aruba</option>
                              <option value="AU">Australia</option>
                              <option value="AT">Austria</option>
                              <option value="AZ">Azerbaijan</option>
                              <option value="BS">Bahamas</option>
                              <option value="BH">Bahrain</option>
                              <option value="BD">Bangladesh</option>
                              <option value="BB">Barbados</option>
                              <option value="BY">Belarus</option>
                              <option value="BE">Belgium</option>
                              <option value="BZ">Belize</option>
                              <option value="BJ">Benin</option>
                              <option value="BM">Bermuda</option>
                              <option value="BT">Bhutan</option>
                              <option value="BO">Bolivia</option>
                              <option value="BQ">
                                Bonaire, Sint Eustatius and Saba
                              </option>
                              <option value="BA">Bosnia and Herzegovina</option>
                              <option value="BW">Botswana</option>
                              <option value="BV">Bouvet Island</option>
                              <option value="BR">Brazil</option>
                              <option value="IO">
                                British Indian Ocean Territory
                              </option>
                              <option value="BN">Brunei Darussalam</option>
                              <option value="BG">Bulgaria</option>
                              <option value="BF">Burkina Faso</option>
                              <option value="BI">Burundi</option>
                              <option value="KH">Cambodia</option>
                              <option value="CM">Cameroon</option>
                              <option value="CA">Canada</option>
                              <option value="CV">Cape Verde</option>
                              <option value="KY">Cayman Islands</option>
                              <option value="CF">
                                Central African Republic
                              </option>
                              <option value="TD">Chad</option>
                              <option value="CL">Chile</option>
                              <option value="CN">China</option>
                              <option value="CX">Christmas Island</option>
                              <option value="CC">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="CO">Colombia</option>
                              <option value="KM">Comoros</option>
                              <option value="CG">Congo</option>
                              <option value="CD">
                                Congo, Democratic Republic of the Congo
                              </option>
                              <option value="CK">Cook Islands</option>
                              <option value="CR">Costa Rica</option>
                              <option value="CI">Cote D'Ivoire</option>
                              <option value="HR">Croatia</option>
                              <option value="CU">Cuba</option>
                              <option value="CW">Curacao</option>
                              <option value="CY">Cyprus</option>
                              <option value="CZ">Czech Republic</option>
                              <option value="DK">Denmark</option>
                              <option value="DJ">Djibouti</option>
                              <option value="DM">Dominica</option>
                              <option value="DO">Dominican Republic</option>
                              <option value="EC">Ecuador</option>
                              <option value="EG">Egypt</option>
                              <option value="SV">El Salvador</option>
                              <option value="GQ">Equatorial Guinea</option>
                              <option value="ER">Eritrea</option>
                              <option value="EE">Estonia</option>
                              <option value="ET">Ethiopia</option>
                              <option value="FK">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="FO">Faroe Islands</option>
                              <option value="FJ">Fiji</option>
                              <option value="FI">Finland</option>
                              <option value="FR">France</option>
                              <option value="GF">French Guiana</option>
                              <option value="PF">French Polynesia</option>
                              <option value="TF">
                                French Southern Territories
                              </option>
                              <option value="GA">Gabon</option>
                              <option value="GM">Gambia</option>
                              <option value="GE">Georgia</option>
                              <option value="DE">Germany</option>
                              <option value="GH">Ghana</option>
                              <option value="GI">Gibraltar</option>
                              <option value="GR">Greece</option>
                              <option value="GL">Greenland</option>
                              <option value="GD">Grenada</option>
                              <option value="GP">Guadeloupe</option>
                              <option value="GU">Guam</option>
                              <option value="GT">Guatemala</option>
                              <option value="GG">Guernsey</option>
                              <option value="GN">Guinea</option>
                              <option value="GW">Guinea-Bissau</option>
                              <option value="GY">Guyana</option>
                              <option value="HT">Haiti</option>
                              <option value="HM">
                                Heard Island and Mcdonald Islands
                              </option>
                              <option value="VA">
                                Holy See (Vatican City State)
                              </option>
                              <option value="HN">Honduras</option>
                              <option value="HK">Hong Kong</option>
                              <option value="HU">Hungary</option>
                              <option value="IS">Iceland</option>
                              <option value="IN">India</option>
                              <option value="ID">Indonesia</option>
                              <option value="IR">
                                Iran, Islamic Republic of
                              </option>
                              <option value="IQ">Iraq</option>
                              <option value="IE">Ireland</option>
                              <option value="IM">Isle of Man</option>
                              <option value="IL">Israel</option>
                              <option value="IT">Italy</option>
                              <option value="JM">Jamaica</option>
                              <option value="JP">Japan</option>
                              <option value="JE">Jersey</option>
                              <option value="JO">Jordan</option>
                              <option value="KZ">Kazakhstan</option>
                              <option value="KE">Kenya</option>
                              <option value="KI">Kiribati</option>
                              <option value="KP">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="KR">Korea, Republic of</option>
                              <option value="XK">Kosovo</option>
                              <option value="KW">Kuwait</option>
                              <option value="KG">Kyrgyzstan</option>
                              <option value="LA">
                                Lao People's Democratic Republic
                              </option>
                              <option value="LV">Latvia</option>
                              <option value="LB">Lebanon</option>
                              <option value="LS">Lesotho</option>
                              <option value="LR">Liberia</option>
                              <option value="LY">Libyan Arab Jamahiriya</option>
                              <option value="LI">Liechtenstein</option>
                              <option value="LT">Lithuania</option>
                              <option value="LU">Luxembourg</option>
                              <option value="MO">Macao</option>
                              <option value="MK">
                                Macedonia, the Former Yugoslav Republic of
                              </option>
                              <option value="MG">Madagascar</option>
                              <option value="MW">Malawi</option>
                              <option value="MY">Malaysia</option>
                              <option value="MV">Maldives</option>
                              <option value="ML">Mali</option>
                              <option value="MT">Malta</option>
                              <option value="MH">Marshall Islands</option>
                              <option value="MQ">Martinique</option>
                              <option value="MR">Mauritania</option>
                              <option value="MU">Mauritius</option>
                              <option value="YT">Mayotte</option>
                              <option value="MX">Mexico</option>
                              <option value="FM">
                                Micronesia, Federated States of
                              </option>
                              <option value="MD">Moldova, Republic of</option>
                              <option value="MC">Monaco</option>
                              <option value="MN">Mongolia</option>
                              <option value="ME">Montenegro</option>
                              <option value="MS">Montserrat</option>
                              <option value="MA">Morocco</option>
                              <option value="MZ">Mozambique</option>
                              <option value="MM">Myanmar</option>
                              <option value="NA">Namibia</option>
                              <option value="NR">Nauru</option>
                              <option value="NP">Nepal</option>
                              <option value="NL">Netherlands</option>
                              <option value="AN">Netherlands Antilles</option>
                              <option value="NC">New Caledonia</option>
                              <option value="NZ">New Zealand</option>
                              <option value="NI">Nicaragua</option>
                              <option value="NE">Niger</option>
                              <option value="NG">Nigeria</option>
                              <option value="NU">Niue</option>
                              <option value="NF">Norfolk Island</option>
                              <option value="MP">
                                Northern Mariana Islands
                              </option>
                              <option value="NO">Norway</option>
                              <option value="OM">Oman</option>
                              <option value="PK">Pakistan</option>
                              <option value="PW">Palau</option>
                              <option value="PS">
                                Palestinian Territory, Occupied
                              </option>
                              <option value="PA">Panama</option>
                              <option value="PG">Papua New Guinea</option>
                              <option value="PY">Paraguay</option>
                              <option value="PE">Peru</option>
                              <option value="PH">Philippines</option>
                              <option value="PN">Pitcairn</option>
                              <option value="PL">Poland</option>
                              <option value="PT">Portugal</option>
                              <option value="PR">Puerto Rico</option>
                              <option value="QA">Qatar</option>
                              <option value="RE">Reunion</option>
                              <option value="RO">Romania</option>
                              <option value="RU">Russian Federation</option>
                              <option value="RW">Rwanda</option>
                              <option value="BL">Saint Barthelemy</option>
                              <option value="SH">Saint Helena</option>
                              <option value="KN">Saint Kitts and Nevis</option>
                              <option value="LC">Saint Lucia</option>
                              <option value="MF">Saint Martin</option>
                              <option value="PM">
                                Saint Pierre and Miquelon
                              </option>
                              <option value="VC">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="WS">Samoa</option>
                              <option value="SM">San Marino</option>
                              <option value="ST">Sao Tome and Principe</option>
                              <option value="SA">Saudi Arabia</option>
                              <option value="SN">Senegal</option>
                              <option value="RS">Serbia</option>
                              <option value="CS">Serbia and Montenegro</option>
                              <option value="SC">Seychelles</option>
                              <option value="SL">Sierra Leone</option>
                              <option value="SG">Singapore</option>
                              <option value="SX">Sint Maarten</option>
                              <option value="SK">Slovakia</option>
                              <option value="SI">Slovenia</option>
                              <option value="SB">Solomon Islands</option>
                              <option value="SO">Somalia</option>
                              <option value="ZA">South Africa</option>
                              <option value="GS">
                                South Georgia and the South Sandwich Islands
                              </option>
                              <option value="SS">South Sudan</option>
                              <option value="ES">Spain</option>
                              <option value="LK">Sri Lanka</option>
                              <option value="SD">Sudan</option>
                              <option value="SR">Suriname</option>
                              <option value="SJ">Svalbard and Jan Mayen</option>
                              <option value="SZ">Swaziland</option>
                              <option value="SE">Sweden</option>
                              <option value="CH">Switzerland</option>
                              <option value="SY">Syrian Arab Republic</option>
                              <option value="TW">
                                Taiwan, Province of China
                              </option>
                              <option value="TJ">Tajikistan</option>
                              <option value="TZ">
                                Tanzania, United Republic of
                              </option>
                              <option value="TH">Thailand</option>
                              <option value="TL">Timor-Leste</option>
                              <option value="TG">Togo</option>
                              <option value="TK">Tokelau</option>
                              <option value="TO">Tonga</option>
                              <option value="TT">Trinidad and Tobago</option>
                              <option value="TN">Tunisia</option>
                              <option value="TR">Turkey</option>
                              <option value="TM">Turkmenistan</option>
                              <option value="TC">
                                Turks and Caicos Islands
                              </option>
                              <option value="TV">Tuvalu</option>
                              <option value="UG">Uganda</option>
                              <option value="UA">Ukraine</option>
                              <option value="AE">United Arab Emirates</option>
                              <option value="GB">United Kingdom</option>
                              <option value="US">United States</option>
                              <option value="UM">
                                United States Minor Outlying Islands
                              </option>
                              <option value="UY">Uruguay</option>
                              <option value="UZ">Uzbekistan</option>
                              <option value="VU">Vanuatu</option>
                              <option value="VE">Venezuela</option>
                              <option value="VN">Viet Nam</option>
                              <option value="VG">
                                Virgin Islands, British
                              </option>
                              <option value="VI">Virgin Islands, U.s.</option>
                              <option value="WF">Wallis and Futuna</option>
                              <option value="EH">Western Sahara</option>
                              <option value="YE">Yemen</option>
                              <option value="ZM">Zambia</option>
                              <option value="ZW">Zimbabwe</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-50">
                        <h3>Payment</h3>
                        <label htmlFor="fname">Accepted Cards</label>
                        <div className="icon-container">
                          <i
                            className="fa fa-cc-visa"
                            style={{ color: "navy" }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <i
                            className="fa fa-cc-amex"
                            style={{ color: "blue" }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <i
                            className="fa fa-cc-mastercard"
                            style={{ color: "red" }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <i
                            className="fa fa-cc-discover"
                            style={{ color: "orange" }}
                          />
                          &nbsp;&nbsp;&nbsp;
                        </div>
                        <label htmlFor="cname">Name on Card</label>
                        <input
                          type="text"
                          id="cname"
                          name="cardname"
                          className="checkouttext"
                          placeholder="John More Doe"
                          value={cardname}
                          onChange={(e) => setCardName(e.target.value)}
                        />
                        <label htmlFor="ccnum">Credit card number</label>
                        <input
                          type="text"
                          id="ccnum"
                          name="cardnumber"
                          className="checkouttext"
                          placeholder="1111-2222-3333-4444"
                          value={cardno}
                          onChange={(e) => setCardno(e.target.value)}
                        />
                        <label htmlFor="expmonth">Exp Month</label>
                        <select
                          id="expmonth"
                          name="expmonth"
                          placeholder="September"
                          className="checkoutselect"
                          value={expmonth}
                          onChange={(e) => setExpmonth(e.target.value)}
                        >
                          <option value="">Select Month</option>
                          <option value="01">Janaury</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>

                        <div className="row">
                          <div className="col-50">
                            <label htmlFor="expyear">Exp Year</label>
                            <input
                              type="text"
                              id="expyear"
                              name="expyear"
                              className="checkouttext"
                              placeholder={2018}
                              value={expyear}
                              onChange={(e) => setExpyear(e.target.value)}
                            />
                          </div>
                          <div className="col-50">
                            <label htmlFor="cvv">CVV</label>
                            <input
                              type="text"
                              id="cvv"
                              name="cvv"
                              className="checkouttext"
                              placeholder={352}
                              value={cvv}
                              onChange={(e) => setCvv(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      defaultValue="Continue to checkout"
                      className="btnsss"
                      onClick={mypay}
                      disabled={disabled}
                    >
                      {disabled ? "Processing Payment" : `Pay $ ${amount}`}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-25">
                <div className="containersss">
                  <h4>
                    <b>Trip Name</b>
                    <span className="price" style={{ color: "black" }}>
                      <b>Trip Price</b>
                    </span>
                  </h4>
                  <p>
                    <a href="#">{tripname}</a>{" "}
                    <span className="price">${amount}</span>
                  </p>

                  <hr />
                  <p>
                    Total{" "}
                    <span className="price" style={{ color: "black" }}>
                      <b>${amount}</b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*************************************
				Main End
		**************************************/}
        {/*************************************
				Footer Start
		**************************************/}
        <Footer />
        {/*************************************
				Footer End
		**************************************/}
      </div>
    </div>
  );
};

export default Checkout;
