import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/footer";
import MainHeader from "../Components/mainheader";
import MobileHeader from "../Components/mobileheader";
import firebase from "../Config/firebase";
const Register = () => {
  const history = useNavigate();

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  useEffect(() => {
    firebase
      .database()
      .ref("counter")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const dataVal = snapshot.val();
          dataVal.register += 1;

          console.log(dataVal);
          firebase.database().ref("counter").set(dataVal);
        } else {
          firebase.database().ref("counter").set({
            homepage: 0,
            about: 0,
            checkout: 0,
            login: 0,
            register: 1,
            tripdetail: 0,
            search: 0,
            contact: 0,
          });
        }
      });
    setTimeout(() => {
      document.getElementById("myloader").style.display = "none";
    }, 2000);
  }, []);
  const signup = () => {
    if (
      fullname === "" ||
      email === "" ||
      password === "" ||
      cpassword === ""
    ) {
      alert("Please Provide All Required Information");
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
              //   firebase.auth().signOut();
              //   alert("Verification Email Sent");
              // Signed in
              var user = userCredential.user;
              firebase
                .database()
                .ref("Customers")
                .child(user.uid)
                .set({
                  fullname: fullname,
                  email: email,
                })
                .then(() => {
                  history("/customer/dashboard");
                });
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
              var user = userCredential.user;
              firebase
                .database()
                .ref("Customers")
                .child(user.uid)
                .set({
                  fullname: fullname,
                  email: email,
                })
                .then(() => {
                  history("/customer/dashboard");
                });
              // ..
            });

          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          alert(errorMessage);
          // ..
        });
    }
  };

  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
            Loader End
**************************************/}
      {/*************************************
        Mobile Menu Start
**************************************/}
      <MobileHeader />{" "}
      {/*************************************
        Mobile Menu End
**************************************/}
      {/*************************************
        Wrapper Start
**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
            Header Start
    **************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
            Header End
    **************************************/}
        {/*************************************
            Inner Banner Start
    **************************************/}
        <section
          className="tg-parallax tg-innerbanner"
          data-appear-top-offset={600}
          data-parallax="scroll"
          style={{
            backgroundImage: 'url("images/lake1.jpeg")',
            backgroundSize: "cover",
          }}
        >
          <div className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h1>Register</h1>
                  <br />
                  <br />
                  <ol className="tg-breadcrumb">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="tg-active">Register</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*************************************
            Inner Banner End
    **************************************/}
        {/*************************************
            Main Start
    **************************************/}
        <main id="tg-main" className="tg-main tg-sectionspace tg-haslayout">
          <div className="container">
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xs-offset-2 col-sm-offset-2 col-md-offset-2 col-lg-offset-2">
                <div id="tg-content" className="tg-content">
                  <h2>
                    <center>Register</center>
                  </h2>
                  <hr />
                  <div className="tg-formtheme tg-formlogin">
                    <fieldset>
                      <div className="form-group">
                        <label>
                          Full Name <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          name="fullname"
                          className="form-control"
                          placeholder="Enter Full Name"
                          required
                          value={fullname}
                          onChange={(e) => setFullname(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Enter Email <sup>*</sup>
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Password <sup>*</sup>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter Passowrd"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Confirm Password <sup>*</sup>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter Confirm Passowrd"
                          required
                          value={cpassword}
                          onChange={(e) => setCpassword(e.target.value)}
                        />
                      </div>
                      <button className="tg-btn tg-btn-lg" onClick={signup}>
                        <span>Register</span>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*************************************
            Main End
    **************************************/}
        {/*************************************
            Footer Start
    **************************************/}
        <Footer />
        {/*************************************
            Footer End
    **************************************/}
      </div>
    </div>
  );
};

export default Register;
