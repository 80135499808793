import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Trips from "./Admin/trips";
import firebase from "../Config/firebase";
import Footer from "../Components/footer";
import MobileHeader from "../Components/mobileheader";
import MainHeader from "../Components/mainheader";

const TripDetail = (props) => {
  const [trip, setTrip] = useState({});
  const location = useLocation();
  const history = useNavigate();

  const [user, setUser] = useState("");
  useEffect(() => {
    console.log(location.state.key);
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        setUser("");
        firebase
          .database()
          .ref("trips")
          .child(location.state.key)
          .on("value", (snapshot) => {
            const dataVal = snapshot.val();
            console.log(dataVal);
            dataVal.key = snapshot.key;

            setTrip(dataVal);
          });

        firebase
          .database()
          .ref("counter")
          .once("value", (snapshot) => {
            if (snapshot.exists()) {
              const dataVal = snapshot.val();
              dataVal.tripdetail += 1;

              console.log(dataVal);
              firebase.database().ref("counter").set(dataVal);
            } else {
              firebase.database().ref("counter").set({
                homepage: 0,
                about: 0,
                checkout: 0,
                login: 0,
                register: 0,
                tripdetail: 1,
                search: 0,
                contact: 0,
              });
            }
          });
        setTimeout(() => {
          document.getElementById("myloader").style.display = "none";
        }, 2000);
      } else {
        firebase
          .database()
          .ref("Customers")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();
            if (dataVal === null) {
              firebase
                .database()
                .ref("trips")
                .child(location.state.key)
                .on("value", (snapshot) => {
                  const dataVal = snapshot.val();
                  console.log(dataVal);
                  dataVal.key = snapshot.key;
                  setTrip(dataVal);
                });
              firebase
                .database()
                .ref("counter")
                .once("value", (snapshot) => {
                  if (snapshot.exists()) {
                    const dataVal = snapshot.val();
                    dataVal.tripdetail += 1;

                    console.log(dataVal);
                    firebase.database().ref("counter").set(dataVal);
                  } else {
                    firebase.database().ref("counter").set({
                      homepage: 0,
                      about: 0,
                      checkout: 0,
                      login: 0,
                      register: 0,
                      tripdetail: 1,
                      search: 0,
                      contact: 0,
                    });
                  }
                });
              setTimeout(() => {
                document.getElementById("myloader").style.display = "none";
              }, 2000);
            } else {
              dataVal.key = snapshot.key;
              setUser(dataVal);
              firebase
                .database()
                .ref("trips")
                .child(location.state.key)
                .on("value", (snapshot) => {
                  const dataVal = snapshot.val();
                  console.log(dataVal);
                  dataVal.key = snapshot.key;

                  setTrip(dataVal);
                });
              firebase
                .database()
                .ref("counter")
                .once("value", (snapshot) => {
                  if (snapshot.exists()) {
                    const dataVal = snapshot.val();
                    dataVal.tripdetail += 1;

                    console.log(dataVal);
                    firebase.database().ref("counter").set(dataVal);
                  } else {
                    firebase.database().ref("counter").set({
                      homepage: 0,
                      about: 0,
                      checkout: 0,
                      login: 0,
                      register: 0,
                      tripdetail: 1,
                      search: 0,
                      contact: 0,
                    });
                  }
                });
              setTimeout(() => {
                document.getElementById("myloader").style.display = "none";
              }, 2000);
            }
          });
      }
    });
  }, []);

  const makepayment = (triptitle, tripprice, tripid) => {
    console.log("Iam Here");
    history("/checkout", {
      state: {
        tripid: tripid,
        tripprice: tripprice,
        triptitle: triptitle,
      },
    });
  };
  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
				Loader End
	**************************************/}
      {/*************************************
			Mobile Menu Start
	**************************************/}
      <MobileHeader />
      {/*************************************
			Mobile Menu End
	**************************************/}
      {/*************************************
			Wrapper Start
	**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
				Header Start
		**************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="../images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
				Header End
		**************************************/}
        {/*************************************
				Inner Banner Start
		**************************************/}
        <img
          src={trip.image}
          className="img-fluid"
          style={{ maxHeight: "35vh", width: "100%" }}
        />
        {/*************************************
				Inner Banner End
		**************************************/}
        {/*************************************
				Main Start
		**************************************/}
        <main id="tg-main" className="tg-main tg-haslayout">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div id="tg-content" className="tg-content">
                  <div className="tg-tourbookingdetail">
                    <div className="tg-bookinginfo">
                      <h2>{trip.tripname}</h2>

                      <div className="tg-pricearea">
                        <h4>${trip.price}</h4>
                      </div>
                      <ul className="tg-tripinfo">
                        <li>
                          <span className="tg-tourduration">
                            {trip.days} Days
                          </span>
                        </li>
                        <li>
                          <span className="tg-tourduration tg-availabilty">
                            {trip.startdate} - {trip.endddate}
                          </span>
                        </li>
                        <li>
                          <span className="tg-tourduration tg-location">
                            Beggining : {trip.blocation} And Destination :{" "}
                            {trip.elocation}
                          </span>
                        </li>
                      </ul>
                      <div
                        className="tg-refundshare"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <div className="tg-refund">
                          <figure>
                            <img
                              src="../images/img-03.jpg"
                              alt="image description"
                            />
                          </figure>
                          <div className="tg-refundinfo">
                            <h3>100% refundable</h3>
                            <div className="tg-description">
                              <p>
                                Cancel up to 12 days before your trip and get a
                                full refund.
                              </p>
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn btn-dark"
                          onClick={() =>
                            makepayment(trip.tripname, trip.price, trip.key)
                          }
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </main>
        {/*************************************
				Main End
		**************************************/}
        {/*************************************
				Footer Start
		**************************************/}
        <Footer />
        {/*************************************
				Footer End
		**************************************/}
      </div>
    </div>
  );
};

export default TripDetail;
