import React from "react";

const Footer = () => {
  return (
    <>
      <footer id="tg-footer" className="tg-footer tg-haslayout">
        <div className="tg-fourcolumns">
          <div className="container">
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <div className="tg-footercolumn tg-widget tg-widgettext">
                  <div className="tg-widgettitle">
                    {/* <h3>About Comapny</h3> */}
                  </div>
                  <div className="tg-widgetcontent">
                    <div className="tg-description">
                      <p style={{ fontStyle: "italic" }}>YOUR TRIP ON TIME</p>
                    </div>
                    <span>+44 744 017 1093</span>
                    <a href="mailto:raygombo@gmail.com">raygombo@gmail.com</a>
                    <ul className="tg-socialicons tg-socialiconsvtwo">
                      <li>
                        <a href="javascript:void(0);">
                          <i className="icon-facebook-logo-outline" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tg-footerbar">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p>Copyright © 2022 Comapny. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
