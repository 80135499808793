import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebase from "../Config/firebase";

const MainHeader = () => {
  const history = useNavigate();

  const [isAdmin, setIsadmin] = useState(false);
  const [isCustomer, setIscustomer] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        setIscustomer(false);
        setIsadmin(false);
      } else {
        firebase
          .database()
          .ref("Admins")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();

            if (dataVal) {
              setIsadmin(true);
            }
          });
        firebase
          .database()
          .ref("Customers")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();

            if (dataVal) {
              setIscustomer(true);
            }
          });
      }
    });
  }, []);
  return (
    <>
      <nav id="tg-nav" className="tg-nav">
        <div className="navbar-header">
          <a href="#menu" className="navbar-toggle collapsed">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </a>
        </div>
        <div
          id="tg-navigation"
          className="collapse navbar-collapse tg-navigation"
        >
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            {isAdmin ? (
              <li>
                <a href="/admin/dashboard">Dashboard</a>
              </li>
            ) : isCustomer ? (
              <li>
                <a href="/customer/dashboard">Dashboard</a>
              </li>
            ) : (
              <>
                <li>
                  <a href="/login">Login</a>
                </li>
                <li>
                  <a href="/register">Register</a>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default MainHeader;
