// importing libraries
import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import firebase from "../Config/firebase";

// main functional component
export const Sidebar = (props) => {
  // library for moving to other screens
  const history = useNavigate();

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history("/login");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // rendering the UI
  return (
    <div
      style={{ display: "flex", height: "100vh", overflow: "scroll initial" }}
    >
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#131428"
        style={{ height: "100vh" }}
      >
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/admin/dashboard"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            Menu
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/admin/dashboard">
              <CDBSidebarMenuItem icon="columns">
                &nbsp;&nbsp;&nbsp;Dashboard
              </CDBSidebarMenuItem>
            </NavLink>
            <br />
            <NavLink exact to="/admin/trips">
              <CDBSidebarMenuItem icon="book">
                &nbsp;&nbsp;&nbsp;All Trips
              </CDBSidebarMenuItem>
            </NavLink>
            <br />
            <NavLink exact to="/admin/bookings">
              <CDBSidebarMenuItem icon="globe">
                &nbsp;&nbsp;&nbsp;All Bookings
              </CDBSidebarMenuItem>
            </NavLink>
            <br />
            <NavLink exact to="/admin/counter">
              <CDBSidebarMenuItem icon="calculator">
                &nbsp;&nbsp;&nbsp;Counter
              </CDBSidebarMenuItem>
            </NavLink>
            <br />
            <NavLink exact to="/admin/messages">
              <CDBSidebarMenuItem icon="inbox">
                &nbsp;&nbsp;&nbsp;Contact Messages
              </CDBSidebarMenuItem>
            </NavLink>
            <br />
            <CDBSidebarMenuItem icon="sign-out" onClick={logout}>
              &nbsp;&nbsp;&nbsp;Logout
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
