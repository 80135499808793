import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebase from "../Config/firebase";

const MobileHeader = () => {
  const history = useNavigate();

  const [isAdmin, setIsadmin] = useState(false);
  const [isCustomer, setIscustomer] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        setIscustomer(false);
        setIsadmin(false);
      } else {
        firebase
          .database()
          .ref("Admins")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();

            if (dataVal) {
              setIsadmin(true);
            }
          });
        firebase
          .database()
          .ref("Customers")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();

            if (dataVal) {
              setIscustomer(true);
            }
          });
      }
    });
  }, []);
  return (
    <>
      <nav id="menu">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/aboutus">About Us</a>
          </li>
          <li>
            <Link to="/contactus">Contact Us</Link>
          </li>
          {isAdmin ? (
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
          ) : isCustomer ? (
            <li>
              <Link to="/customer/dashboard">Dashboard</Link>
            </li>
          ) : (
            <>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
};

export default MobileHeader;
