// Programmer Name: MR. Wasi
// Program Name: Failed.js
// Description: This file include the functionality when the buyer fails to make payment, it will delete order from database
// First Written on: start from 25th august and ended on September 30th.
// Edited on: start from 25th august and ended on September 30th

// importing libraries

import React, { useEffect } from "react";
import firebase from "../../Config/firebase";

// main functional component
export const Failed = (props) => {
  // this function will run firstly
  useEffect(() => {
    // the below code will get order id from parameter
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderid = urlParams.get("session_id");

    // the specific order will get delete from database
    firebase.database().ref("orders").child(orderid).remove();

    // the user will get to home page after 5 seconds
    const timer = setTimeout(() => {
      window.location.href = "/";
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // rendering the UI
  return (
    <div
      style={{
        backgroundColor: "#1E2639",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 style={{ color: "white", fontSize: 50, fontWeight: "bolder" }}>
        Payment Failed
      </h1>
      <br />
      <br />
      <p style={{ color: "white", fontSize: 25, fontWeight: "bolder" }}>
        Redirecting To Home Page In Five Second
      </p>
    </div>
  );
};

export default Failed;
