import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/footer";
import MainHeader from "../Components/mainheader";
import MobileHeader from "../Components/mobileheader";
import firebase from "../Config/firebase";

const Login = () => {
  useEffect(() => {
    firebase
      .database()
      .ref("counter")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const dataVal = snapshot.val();
          dataVal.login += 1;

          console.log(dataVal);
          firebase.database().ref("counter").set(dataVal);
        } else {
          firebase.database().ref("counter").set({
            homepage: 0,
            about: 0,
            checkout: 0,
            login: 1,
            register: 0,
            tripdetail: 0,
            search: 0,
            contact: 0,
          });
        }
      });
    setTimeout(() => {
      document.getElementById("myloader").style.display = "none";
    }, 2000);
  }, []);

  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("Customer");

  const submit = () => {
    if (type === "Customer") {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          firebase
            .database()
            .ref("Customers")
            .child(result.user.uid)
            .on("value", (snapshot) => {
              if (snapshot.exists()) {
                history("/customer/dashboard");
              } else {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    // Sign-out successful.

                    alert("Customer Not Found");
                  })
                  .catch((error) => {
                    // An error happened.
                    alert("Customer Login Error");
                  });
              }
            });
        })
        .catch(function (error) {
          var errorMessage = error.message;
          alert(errorMessage);
        });
    } else if (type === "Admin") {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          firebase
            .database()
            .ref("Admins")
            .child(result.user.uid)
            .on("value", (snapshot) => {
              if (snapshot.exists()) {
                history("/admin/dashboard");
              } else {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    // Sign-out successful.

                    alert("Admin Not Found");
                  })
                  .catch((error) => {
                    // An error happened.
                    alert("Admin Login Error");
                  });
              }
            });
        })
        .catch(function (error) {
          var errorMessage = error.message;
          alert(errorMessage);
        });
    } else {
      alert("Invalid User");
    }
  };
  return (
    <div>
      <div id="myloader" className="loader">
        <div className="span">
          <div className="location_indicator" />
        </div>
      </div>
      {/*************************************
				Loader End
	**************************************/}
      {/*************************************
			Mobile Menu Start
	**************************************/}
      <MobileHeader />
      {/*************************************
			Mobile Menu End
	**************************************/}
      {/*************************************
			Wrapper Start
	**************************************/}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        {/*************************************
				Header Start
		**************************************/}
        <header id="tg-header" className="tg-header tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="tg-navigationarea tg-headerfixed">
                <strong className="tg-logo">
                  <a href="/">
                    <img src="images/logo.png" alt="RABBI logo here" />
                  </a>
                </strong>
                <MainHeader />
              </div>
            </div>
          </div>
        </header>
        {/*************************************
				Header End
		**************************************/}
        {/*************************************
				Inner Banner Start
		**************************************/}
        <section
          className="tg-parallax tg-innerbanner"
          data-appear-top-offset={600}
          data-parallax="scroll"
          style={{
            backgroundImage: 'url("images/lake1.jpeg")',
            backgroundSize: "cover",
          }}
        >
          <div className="tg-sectionspace tg-haslayout">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h1>Login</h1>
                  <br />
                  <br />
                  <ol className="tg-breadcrumb">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="tg-active">Login</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*************************************
				Inner Banner End
		**************************************/}
        {/*************************************
				Main Start
		**************************************/}
        <main id="tg-main" className="tg-main tg-sectionspace tg-haslayout">
          <div className="container">
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xs-offset-2 col-sm-offset-2 col-md-offset-2 col-lg-offset-2">
                <div id="tg-content" className="tg-content">
                  <h2>
                    <center>Login</center>
                  </h2>
                  <hr />
                  <div className="tg-formtheme tg-formlogin">
                    <fieldset>
                      <div className="form-group">
                        <label>
                          Enter Email <sup>*</sup>
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Password <sup>*</sup>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Login As</label>
                        <span style={{ width: "100%" }}>
                          <select
                            className="form-control"
                            value={type}
                            style={{ width: "100%", height: "42px" }}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="Customer">Customer</option>
                            <option value="Admin">Admin</option>
                          </select>
                        </span>
                      </div>
                      <button className="tg-btn tg-btn-lg" onClick={submit}>
                        <span>Login</span>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*************************************
				Main End
		**************************************/}
        {/*************************************
				Footer Start
		**************************************/}
        <Footer />
        {/*************************************
				Footer End
		**************************************/}
      </div>
    </div>
  );
};

export default Login;
