import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import firebase from "../../Config/firebase";
import { Sidebar } from "../../Components/sidebar.js";
import "../../Css/home.css";
import { v4 as uuid } from "uuid";

export const AddTrip = (props) => {
  const history = useNavigate();

  const [user, setUser] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [tripname, setTripname] = useState("");
  const [blocation, setBlocation] = useState("Baraka");
  const [elocation, setElocation] = useState("Dine");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [days, setDays] = useState(1);
  const [description, setDescription] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        history("/");
      } else {
        firebase
          .database()
          .ref("Admins")
          .child(firebaseUser.uid)
          .once("value", (snapshot) => {
            const dataVal = snapshot.val();
            if (dataVal === null) {
              history("/login");
              window.location.reload();
            } else {
              setUser(dataVal);
              setTimeout(() => {
                setIsloading(false);
              }, 2000);
            }
          });
      }
    });
  }, [history]);

  const uploadimage = async (e) => {
    const file = e.target.files[0];
    alert("Please Wait Until You Get Confirmation Message");
    const id = uuid();
    const storageRef = firebase.storage().ref("posts").child(id);
    await storageRef.put(file);
    storageRef.getDownloadURL().then((url) => {
      setImage(url);
      alert("Image Inserted");
    });
  };

  const submit = () => {
    if (
      tripname === "" ||
      blocation === "" ||
      elocation === "" ||
      price <= 0 ||
      days <= 0
    ) {
      alert("Enter Valid Information");
    } else if (blocation === elocation) {
      alert("Beginning and Destination Location Cant Be Same");
    } else {
      firebase.database().ref("trips").push({
        tripname: tripname,
        blocation: blocation,
        elocation: elocation,
        price: price,
        image: image,
        days: days,
        description: description,
        startdate: startdate,
        endddate: enddate,
      });
      alert("Trip Added");
      history("/admin/trips");
    }
  };
  // rendering the UI
  if (isLoading === true) {
    return (
      <>
        <div className="loadingscreen">
          <h1 style={{ fontSize: "45px", color: "white" }}>Please Wait</h1>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="page-top" style={{ width: "min-content", minWidth: "100vw" }}>
          <div id="wrapper" style={{ backgroundColor: "#131428" }}>
            {/* Importing Buyer Sidebar */}
            <Sidebar />

            <div className="d-flex flex-column" id="content-wrapper">
              <div id="content">
                {/* Importing Buyer Header */}
                <Header />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {/* The below code will display buyer name from user state */}
                  <h1>Add New Trip</h1>
                  <br />
                  <label>Trip Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Trip Name"
                    value={tripname}
                    onChange={(e) => setTripname(e.target.value)}
                  />
                  <br />
                  <br />
                  <label>Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={uploadimage}
                  />
                  <br />
                  <br />
                  <label>Beginning Location</label>
                  <select
                    className="form-control"
                    name="beginning"
                    data-live-search="true"
                    data-width="100%"
                    value={blocation}
                    style={{ display: "block !important" }}
                    onChange={(e) => setBlocation(e.target.value)}
                  >
                    <option data-tokens="Baraka">Baraka</option>
                    <option data-tokens="Dine">Dine</option>
                    <option data-tokens="Kazimia">Kazimia</option>
                    <option data-tokens="Kihimino">Kihimino</option>
                    <option data-tokens="Mwayenga">Mwayenga</option>
                    <option data-tokens="Uvira">Uvira</option>
                  </select>{" "}
                  <br />
                  <br />
                  <label>Destination Location</label>
                  <select
                    className="form-control"
                    name="beginning"
                    data-live-search="true"
                    data-width="100%"
                    value={elocation}
                    style={{ display: "block !important" }}
                    onChange={(e) => setElocation(e.target.value)}
                  >
                    <option data-tokens="Baraka">Baraka</option>
                    <option data-tokens="Dine">Dine</option>
                    <option data-tokens="Kazimia">Kazimia</option>
                    <option data-tokens="Kihimino">Kihimino</option>
                    <option data-tokens="Mwayenga">Mwayenga</option>
                    <option data-tokens="Uvira">Uvira</option>
                  </select>{" "}
                  <br />
                  <br />
                  <label>Start Date</label>
                  <input
                    type="date"
                    value={startdate}
                    onChange={(e) => setstartdate(e.target.value)}
                    className="form-control"
                  />
                  <br />
                  <br />
                  <label>End Date</label>
                  <input
                    type="date"
                    value={enddate}
                    onChange={(e) => setenddate(e.target.value)}
                    className="form-control"
                  />
                  <br />
                  <br />
                  <label>Price</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Trip Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <br />
                  <br />
                  <label>Description</label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"
                    style={{ height: "200px", width: "100%" }}
                  />
                  <br />
                  <br />
                  <label>No Of Days</label>
                  <input
                    type="number"
                    value={days}
                    min="1"
                    max=""
                    onChange={(e) => setDays(e.target.value)}
                    className="form-control"
                  />
                  <br />
                  <br />
                  <button
                    className="btn btn-success"
                    style={{ height: "40px", width: "100%" }}
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AddTrip;
